
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import t from "@common/src/i18n/t";

@Component({
  inheritAttrs: false
})
export default class SingleSearch extends Vue {
  @Prop({ default: t("base.please-enter-the-content") })
  placeholder: string;
  search() {
    this.$emit("search");
  }
}
